








































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import BaseLayout from '@/layouts/BaseLayout.vue'
import {
  NotifyBadge,
  HttpGet,
  HttpPost,
  Tooltip,
  TagBox,
  DataTable,
  Card,
  Workbar,
  Button,
  DataTableColumn,
  Textbox,
  Action,
  Notifier,
  Searchbox,
  Badge,
  SearchDropdown,
  DeleteConfirmationModal,
  Modal,
  Dropdown,
  CheckDropdown,
  Check
} from '../../lib/mavejs'

import { Functions } from '@/mixins/Functions'
import TextForm from '@/views/text/form/TextForm.vue'
import { TextValidation } from '@/classes/TextValidation'

@Component({
  components: {
    TextForm,
    TagBox,
    HttpGet,
    Tooltip,
    SearchDropdown,
    Textbox,
    DataTableColumn,
    DataTable,
    HttpPost,
    Searchbox,
    BaseLayout,
    Card,
    Badge,
    Action,
    Workbar,
    Button,
    DeleteConfirmationModal,
    Modal,
    NotifyBadge,
    Dropdown,
    CheckDropdown,
    Check
  },
  mixins: [Functions]
})
export default class Index extends Vue {
  showDelete = false
  tagOptions = []
  search = ''

  changed = []
  selectedLang = {
    value: 1,
    label: 'French'
  }

  textToDelete = {
    id: '',
    name: ''
  }

  filters = {
    search: { column: 'content', value: '' },
    type: null,
    withoutTranslations: { value: false, language: this.selectedLang.value },
    tags: []
  }

  headers = [
    { head: 'Type', key: 'type' },
    { head: 'Text', key: 'text' },
    { head: 'Translation', key: 'translation' },
    { head: 'Tags', key: 'tags' },
    { head: 'Added Date', key: 'added_date' },
    { head: '', key: '' }
  ]

  sorted = { head: 'text', key: 'text' }

  $refs: {
    languages: HttpGet;
    textModal: Modal;
    submit: Button;
    tags: HttpGet;
    deleteModal: DeleteConfirmationModal;
    languageModal: Modal;
    validationModal: Modal;
    post: HttpPost;
  }

  changeLanguage (lang) {
    this.filters.withoutTranslations.language = lang.value
    this.selectedLang = lang
  }

  validate (row, value = '') {
    const validation = new TextValidation(row.type, row[value], (value === 'descriptionTranslation' || value === 'description'))
    validation.validate()
    if (row.validation === undefined) {
      row.validation = { content: '', translation: '', description: '', descriptionTranslation: '' }
    }

    row.validation[value] = validation.getError()
  }

  detect (row, value = '') {
    if (value !== '') {
      this.validate(row, value)
    }

    this.changed = this.changed.filter(item => item.id !== row.id)
    this.changed.push(row)
  }

  openDeleteModal (id, name) {
    this.$refs.deleteModal.open()
    this.textToDelete = { id: id, name: name }
    this.showDelete = true
  }

  submitTranslations () {
    for (const row of this.changed) {
      if (row.validation === null || row.validation.content !== '' || row.validation.translation !== '' || row.validation.description !== '' || row.validation.descriptionTranslation !== '') {
        this.$refs.validationModal.isOpen = true
        return
      }
    }

    this.$refs.post.post()
  }

  handleSave (success) {
    if (success) {
      this.$refs.languages.fetch()
      this.$refs.tags.fetch()
      this.changed = []
    }
  }

  handleSaveResponse (fetch) {
    this.$refs.submit.isLoading = fetch.isFetching
  }

  handleTextForm (fetch) {
    if (fetch !== null && fetch.data !== undefined && fetch.data.status === 200) {
      this.$refs.textModal.isOpen = false
      this.$refs.languages.fetch()
      Notifier.send({ message: 'New text created' })
    }
  }
}
