var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Validation',{key:_vm.response,ref:"validation",attrs:{"response":_vm.response},scopedSlots:_vm._u([{key:"default",fn:function(validation){return [_c('Textbox',{staticClass:"mt-3",attrs:{"label":"Text","validation":_vm.s(validation.validation, 'content')},model:{value:(_vm.text.content),callback:function ($$v) {_vm.$set(_vm.text, "content", $$v)},expression:"text.content"}}),_c('Dropdown',{staticClass:"mt-3",attrs:{"label":"Text Type","options":[
        { value: null, label: 'No type'},
        { value: 1, label: 'Youtube Title' },
        { value: 2, label: 'CTA' },
        { value: 3, label: 'Search Title' },
        { value: 4, label: 'Sitelink' },
        { value: 5, label: 'Search Keyword'},
        { value: 6, label: 'Call Out Extension'},
        { value: 7, label: 'Snippet'},
        { value: 9, label: 'Url Path'}
      ],"validation":_vm.s(validation.validation, 'type')},model:{value:(_vm.text.type),callback:function ($$v) {_vm.$set(_vm.text, "type", $$v)},expression:"text.type"}}),_c('TagBox',{staticClass:"my-3",attrs:{"label":"Tags"},model:{value:(_vm.text.tags),callback:function ($$v) {_vm.$set(_vm.text, "tags", $$v)},expression:"text.tags"}})]}}])}),_c('HttpPost',{attrs:{"url":_vm.url('/api/1.0/text/create'),"body":_vm.text},on:{"success":function (success) { return _vm.handleSuccess(success); },"fetch":function (fetch) { return _vm.handleResponse(fetch); }}},[_c('Button',{ref:"submit",attrs:{"label":"Create Text","variant":((_vm.isSuccessFull === true) ? 'primary' : 'danger')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }