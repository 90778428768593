































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Validation, Functions, DropdownBehaviour, Button, Textbox, Dropdown, Form, HttpPost, TagBox } from '../../../lib/mavejs'
import { url } from '@/functions'

@Component({
  components: { DropdownBehaviour, Validation, Textbox, Dropdown, HttpPost, TagBox, Button },
  mixins: [Form, Functions],
  methods: { url: url }
})
export default class TextForm extends Vue {
  @Prop() endpoint!: string
  @Prop() title!: string
  @Prop() label!: string
  @Prop({ default: '/texts' }) redirect!: string

  isSuccessFull = true;
  text = {
    content: '',
    type: '',
    tags: []
  }

  $refs: {
    submit: Button;
    validation: Validation;
  }

  response = null;

  handleResponse (fetch) {
    this.$refs.submit.isLoading = fetch.isFetching
    this.$refs.validation.$forceUpdate()
    this.response = fetch.data

    this.$emit('submit', this.response)
  }
}
