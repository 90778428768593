export class TextValidation {
  ctaCharLimit = 10;
  titleCharLimit = 15;
  siteLinkCharLimit = 25;
  searchCharLimit = 30;
  descriptionCharLimit = 35;
  keywordCharLimit = 100;
  calloutExtensionLimit = 100;
  snippetLimit = 100;
  urlPathLimit = 100;

  type: string;
  text: string;
  isDescription: boolean;

  error = ''

  constructor (type, text, isDescription = false) {
    this.type = type
    this.text = text
    this.isDescription = isDescription
  }

  getCharLimit (type) {
    if (this.isDescription) {
      return this.descriptionCharLimit
    }

    if (type === 'CTA') {
      return this.ctaCharLimit
    }

    if (type === 'Youtube Title') {
      return this.titleCharLimit
    }

    if (type === 'Search Title') {
      return this.searchCharLimit
    }

    if (type === 'SiteLink') {
      return this.siteLinkCharLimit
    }

    if (type === 'Keyword') {
      return this.keywordCharLimit
    }

    if (type === 'CallOutExtension') {
      return this.calloutExtensionLimit
    }

    if (type === 'Snippet') {
      return this.snippetLimit
    }

    if (type === 'UrlPath') {
      return this.urlPathLimit
    }
  }

  validate () {
    const limit = this.getCharLimit(this.type)

    if (this.text === null || this.text.length <= limit) {
      this.error = ''
      return true
    }

    this.error = this.type + (this.isDescription ? ' description' : '') + ' has too much characters'

    return false
  }

  getError () {
    return this.error
  }
}
